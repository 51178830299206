import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useState } from 'react';

import useRole from '../../hooks/useRole';
import { Header } from './Header';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    flexGrow: 2,
  },
});

const AppLayout = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();
  const { hasAccess } = useRole();

  const [openAuthorizeDialog, setOpenAuthorizeDialog] = useState(true);

  return (
    <div className={classes.root}>
      <Header />
      <AuthenticatedTemplate>
        {hasAccess ? (
          <main className={classes.main}>{children}</main>
        ) : (
          <Box p={1}>
            <h1>Unauthorized</h1>
            <p>
              Your account has no access, please contact{' '}
              <a href="mailto:admin@realtalogic.com">admin@realtalogic.com</a> if this is unexpected.
            </p>
            <Dialog open={openAuthorizeDialog} onClose={() => setOpenAuthorizeDialog(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Unauthorized</DialogTitle>
              <DialogContent>
                Your account has no access, please contact{' '}
                <a href="mailto:admin@realtalogic.com">admin@realtalogic.com</a> if this is unexpected.
              </DialogContent>
              <DialogActions>
                <Button color="inherit" onClick={() => setOpenAuthorizeDialog(false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2">Please login to use this app</Typography>
        </Box>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default AppLayout;
