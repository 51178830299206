import { AccountInfo } from '@azure/msal-browser';
import { useLocation } from '@gatsbyjs/reach-router';
import { Box, Button, Paper } from '@mui/material';
import { Link } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent } from '../../services';
import { setCookie } from '../../services/cookie';
import { initAuthState } from '../../store/auth';
import { getMsalInstance } from '../../utils/get-msal-instance';
import { ProjectHeader } from './ProjectHeader';

const HEADER_HEIGHT = '48px';

const Header: React.FC = memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const msalInstance = getMsalInstance();

  const [account, setAccount] = useState<AccountInfo | null>(msalInstance.getActiveAccount());

  useEffect(() => {
    const init = async () => {
      await msalInstance.initialize();

      // if (getCookie('ssoAccount')) {
      //   const response = await msalInstance.ssoSilent({
      //     scopes: ['User.Read'],
      //     loginHint: getCookie('ssoAccount'),
      //   });
      //   setAccount(response.account);
      // }
    };

    init();
    dispatch(initAuthState());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogoClick = () => {
    trackEvent.logoClick();
  };

  const handleLogin = () => {
    localStorage.setItem('redirectToAfterAuth', location.pathname);
    msalInstance
      .loginPopup({
        redirectUri: process.env.MSAL_CALLBACK,
        scopes: ['user.read'],
      })
      .then((response) => {
        msalInstance.setActiveAccount(response.account);
        setAccount(response.account);
        setCookie('ssoAccount', response.account.username);
      });
  };

  const handleLogout = () => {
    localStorage.setItem('redirectToAfterAuth', location.pathname);
    msalInstance.logoutPopup();
    setAccount(null);
  };

  const loginButton =
    location.pathname === '/consent-form/' ? null : !account ? (
      <Button onClick={handleLogin} sx={{ fontWeight: 600, color: '#fff' }}>
        Login / Signup
      </Button>
    ) : (
      <div>
        <Button onClick={handleLogout} sx={{ fontWeight: 600, color: '#fff' }}>
          Logout
        </Button>
      </div>
    );

  return (
    <Paper
      sx={{
        height: HEADER_HEIGHT,
        paddingLeft: '1rem',
        backgroundColor: '#000',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
      }}
      elevation={1}
    >
      <Link
        to="/documents"
        style={{ textDecoration: 'none', color: 'inherit', marginTop: '5px' }}
        onClick={handleLogoClick}
      >
        <Box
          component="img"
          src="https://uploads-ssl.webflow.com/614cea66a00d7d482d67ff6a/614d9b248fca55c2966fa587_Logo.svg"
          px={1}
          bgcolor="#fff"
        />
      </Link>
      <ProjectHeader />
      <div style={{ textAlign: 'right', flexGrow: 1, paddingRight: '10px' }}>{loginButton}</div>
    </Paper>
  );
});

export { Header, HEADER_HEIGHT };
