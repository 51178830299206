import { navigate } from '@gatsbyjs/reach-router';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Paper, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRole from '../../hooks/useRole';
import { dashboardSelector } from '../../store/dashboard';
import {
  Project,
  projectSelectors,
  updateActiveProjectId,
  updateActiveWorkspaceId,
  updateParentProjectId,
} from '../../store/project';
import { setWorkspaceReadMode, workspaceSelectors } from '../../store/workspace';

const HEADER_HEIGHT = '48px';

const paperStyle = {
  height: HEADER_HEIGHT,
  paddingLeft: '2rem',
  backgroundColor: '#000',
  display: 'flex',
  alignItems: 'center',
};

const ProjectHeader: React.FC = () => {
  const { isSearcher } = useRole();
  const dispatch = useDispatch();

  const activeDashboardId: any = useSelector(dashboardSelector.activeDashboardId);
  const activeUserEmailId: any = useSelector(dashboardSelector.activeUserEmailId);

  const nestedParents: any[] = useSelector(projectSelectors.nestedParents);
  const projectFlow: string = useSelector(projectSelectors.projectFlow);
  const projectHierRootProjectId = useSelector(projectSelectors.projectHierRootProjectId);
  const activeWorkspaceId: string = useSelector(projectSelectors.activeWorkspaceId);
  const activeWorkspace = useSelector(workspaceSelectors.WorkspaceById(activeWorkspaceId));
  const [workspaceName, setWorkspaceName] = useState(activeWorkspace.name);

  useEffect(() => {
    setWorkspaceName(activeWorkspace.name);
  }, [activeWorkspace]);

  const handleOnClick = () => {
    dispatch(updateActiveProjectId(''));
    dispatch(updateParentProjectId(''));
    dispatch(updateActiveWorkspaceId(''));
    dispatch(setWorkspaceReadMode(false));
  };

  const handleSearch = () => {
    navigate('/search');
  };

  // if (!rootProjectId) {
  //   return <></>;
  // }

  return (
    <Paper sx={paperStyle} elevation={0}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb" sx={{ color: '#fff' }}>
        {activeDashboardId && (
          <Link
            color="inherit"
            to={`/dashboard/${activeDashboardId}`}
            style={{
              textDecoration: 'none',
              color: '#fff',
              marginLeft: '20px',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
            onClick={handleOnClick}
          >
            {'Home'}
          </Link>
        )}
        {activeUserEmailId && (
          <Link
            color="inherit"
            to={`/dashboard`}
            style={{
              textDecoration: 'none',
              color: '#fff',
              marginLeft: '20px',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
            onClick={handleOnClick}
          >
            {'Home'}
          </Link>
        )}
        {projectFlow === 'publicProjectView' && (
          <Link
            color="inherit"
            to={`/project/publicProjects`}
            style={{
              textDecoration: 'none',
              color: '#fff',
              marginLeft: '20px',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
            onClick={handleOnClick}
          >
            {'PublicProjects'}
          </Link>
        )}
        {projectFlow === 'projectHierView' && projectHierRootProjectId.length > 0 && (
          <Link
            color="inherit"
            to={`/project/${projectHierRootProjectId}`}
            style={{
              textDecoration: 'none',
              color: '#fff',
              marginLeft: '20px',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
            onClick={handleOnClick}
          >
            {'Projects'}
          </Link>
        )}
        {activeWorkspaceId &&
          activeWorkspaceId.length > 0 &&
          nestedParents.map((parent: Project) => {
            return (
              <Link
                key={parent.id}
                color="inherit"
                to={`/project/${parent.id}`}
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  fontWeight: 'bold',
                  textDecorationStyle: 'inherit',
                }}
              >
                {parent.name ? parent.name : 'Untitled project'}
              </Link>
            );
          })}
        {activeWorkspaceId && activeWorkspaceId.length > 0 && (
          <Typography
            color="textPrimary"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
          >
            {workspaceName}
          </Typography>
        )}
        {isSearcher && (
          <Link
            color="inherit"
            to={`/search`}
            style={{
              textDecoration: 'none',
              color: '#fff',
              marginLeft: '20px',
              fontWeight: 'bold',
              textDecorationStyle: 'inherit',
            }}
            onClick={handleSearch}
          >
            Search
          </Link>
        )}
      </Breadcrumbs>
    </Paper>
  );
};

export { ProjectHeader };
